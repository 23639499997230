import styled from '@emotion/styled';
import { Container } from '@layouts';

export const Wrapper = styled.footer`
  background: ${props => props.theme.colors.primary.lighter};
  display: flex;
  flex-direction: column;
`;

export const LinkWrapper = styled.div`
  display: flex;
  > section {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

export const ContainerWrapper = styled(Container)`
  flex-flow: row wrap;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 240px;
  padding: 10px 20px;
`;

export const Title = styled.h3`
  text-transform: capitalize;
`;

export const FooterItem = styled.a`
  display: block;
  font-size: 13px;
  line-height: 2.5;
  font-family: ${props => props.theme.fontFamily.body};
  color: ${props => props.theme.colors.black.light};
  transition: color 0.2s;

  &:hover {
    color: ${props => props.theme.colors.primary.base};
  }
`;

export const CopyRightWrapper = styled.div`
  padding: 20px 0 30px 0;
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${props => props.theme.colors.white.grey};
  justify-content: center;
`;

export const CopyRightText = styled.div`
  font-size: 13px;
  color: ${props => props.theme.colors.black.light};
`;

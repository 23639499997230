import styled from '@emotion/styled';
import { Container } from '@layouts';
import UserDefaultImage from '@assets/testimonials/user-default.svg';

export const TestimonialRow = styled.div`
  background: ${props => props.theme.colors.white.lightest};
  justify-content: center;
`;
export const Wrapper = styled(Container)`
  padding-top: 30px;
`;
export const SmileyIcon = styled.img`
  width: 32px;
  margin: 0 10px;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: none;
  }
`;

export const SliderContainer = styled.div`
  position: relative;
  .owl-stage-outer {
    overflow-x: hidden;
  }
  .owl-stage {
    display: flex;
    overflow-x: hidden;
  }
  .owl-dots {
    margin-top: 25px !important;
  }
  .owl-dots button {
    outline: none;
  }
  .owl-dot span {
    background: #869791 !important;
    height: 12px !important;
    width: 12px !important;
  }
  .owl-dot:hover span {
    background: #fff;
  }
  .owl-dot.active span {
    background: #000 !important;
  }

  .owl-nav {
    display: none;
  }
`;

export const Slider = styled.div`
  position: relative;
  margin: 60px 0 20px 0;
`;

export const Testimonial = styled.div`
  display: flex;
  margin: 0 10px;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
    align-items: center;
  }
`;
export const TestimonialImageWrapper = styled.div`
  margin-right: 20px;
  width: 90px;
  min-width: 80px;
`;
export const TestimonialImage = styled.img`
  width: 100%;
`;
export const TestimonialDefaultImage = styled(UserDefaultImage)`
  width: 100%;
`;
export const TestimonialContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    text-align: center;
  }
`;
export const TestimonialTitle = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
`;
export const TestimonialSubtitle = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
`;
export const TestimonialFeedback = styled.div`
  font-family: Montserrat;
  font-size: 20px;
  margin-top: 10px;
  color: #535353;
  line-height: 1.75em;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 14px;
    margin-top: 30px;
  }
`;
export const QuoteStartIcon = styled.img`
  width: 32px;
  display: inline;
  margin: 0 10px 0 0;
`;
export const QuoteEndIcon = styled.img`
  width: 20px;
  display: inline;
  margin: 0 0 0 8px;
`;

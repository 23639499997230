export const subServiceRoutes = [
  {
    name: 'Android App',
    route: 'android-app',
  },
  {
    name: 'Blockchain',
    route: 'block-chain',
  },
  {
    name: 'Cross Platform',
    route: 'cross-platform',
  },
  {
    name: 'Data Visualization',
    route: 'data-visualization',
  },
  {
    name: 'E-Commerce',
    route: 'e-commerce',
  },
  {
    name: 'Enterprise Solution',
    route: 'enterprise-solution',
  },
  {
    name: 'iOS Development',
    route: 'ios-development',
  },
  {
    name: 'Mobile First',
    route: 'mobile-first',
  },
  {
    name: 'QA service',
    route: 'qa-service',
  },
  {
    name: 'React & React Native',
    route: 'react-n-react-native',
  },
  {
    name: 'Technolgy Stack',
    route: 'tech-stack',
  },
  {
    name: 'Wearable Technolgy',
    route: 'wearable-tech',
  },
  {
    name: 'Web Development',
    route: 'web-development',
  },
];

export const subIndustries = [
  {
    name: 'Automobile',
    route: 'automobile',
  },
  {
    name: 'Education',
    route: 'education',
  },
  {
    name: 'Enterprise',
    route: 'enterprise',
  },
  {
    name: 'Event',
    route: 'event',
  },
  {
    name: 'Fintech',
    route: 'fintech',
  },
  {
    name: 'Health Care',
    route: 'health-care',
  },
  {
    name: 'Virtual Reality',
    route: 'virtual-reality',
  },
  {
    name: 'On Demand',
    route: 'on-demand',
  },
  {
    name: 'Real Estate',
    route: 'real-estate',
  },
  {
    name: 'Social Media',
    route: 'social-media',
  },
  {
    name: 'Travel',
    route: 'travel',
  },
  {
    name: 'Media & Entertainment',
    route: 'media-and-entertainment',
  },
];

export const subTechnology = [
  {
    name: 'Angular',
    route: 'angular',
  },
  {
    name: 'React',
    route: 'react',
  },
  {
    name: 'Swift/Objective C',
    route: 'swift-objectiveC',
  },
  {
    name: 'AWS',
    route: 'aws',
  },
  {
    name: 'MongoDB',
    route: 'mongodb',
  },
  {
    name: 'React Native',
    route: 'react-native',
  },
  {
    name: 'Docker',
    route: 'docker',
  },
  {
    name: 'Elasticsearch',
    route: 'elastic-search',
  },
  {
    name: 'Google Cloud',
    route: 'google-cloud',
  },
  {
    name: 'Java/Kotlin',
    route: 'java',
  },
  {
    name: 'Django',
    route: 'django',
  },
  {
    name: 'ExpressJS',
    route: 'express-js',
  },
  {
    name: 'GatsbyJS',
    route: 'gatsby-js',
  },
  {
    name: 'Laravel',
    route: 'laravel',
  },
  {
    name: 'NodeJS',
    route: 'node',
  },
  {
    name: 'PostgreSQL',
    route: 'postgre',
  },
  {
    name: 'Shopify',
    route: 'shopify',
  },
];

export const applicationURLs = {
  HOME: '/',
  SERVICES: '/services',
  INDUSTRIES: '/industries',
  TECHNOLOGIES: '/technologies',
  PROCESS: '/process',
  CONTACT_US: '/contact-us',
  ABOUT_US: '/about-us',
  PORTFOLIO: '/portfolio',
};

const footerPage = {
  services: [
    {
      label: 'iOS Development',
      link: '/services/ios-development',
    },
    {
      label: 'Android App',
      link: '/services/android-app',
    },
    {
      label: 'Web Development',
      link: '/services/web-development',
    },
    {
      label: 'Blockchain',
      link: '/services/block-chain',
    },
    {
      label: 'Cross Platform',
      link: '/services/cross-platform',
    },
    {
      label: 'Data Visualization',
      link: '/services/data-visualization',
    },
    {
      label: 'E-Commerce',
      link: '/services/e-commerce',
    },
    {
      label: 'Enterprise Solution',
      link: '/services/enterprise-solution',
    },
    {
      label: 'Mobile First',
      link: '/services/mobile-first',
    },
    {
      label: 'QA service',
      link: '/services/qa-service',
    },
    {
      label: 'React & React Native',
      link: '/services/react-n-react-native',
    },
    {
      label: 'Technolgy Stack',
      link: '/services/tech-stack',
    },
    {
      label: 'Wearable Technolgy',
      link: '/services/wearable-tech',
    },
  ],
  industries: [
    {
      label: 'Automobile',
      link: '/industries/automobile',
    },
    {
      label: 'Education',
      link: '/industries/education',
    },
    {
      label: 'Enterprise',
      link: '/industries/enterprise',
    },
    {
      label: 'Event',
      link: '/industries/event',
    },
    {
      label: 'Fintech',
      link: '/industries/fintech',
    },
    {
      label: 'Health Care',
      link: '/industries/health-care',
    },
    {
      label: 'Media & Entertainment',
      link: '/industries/media-and-entertainment',
    },
    {
      label: 'On Demand',
      link: '/industries/on-demand',
    },
    {
      label: 'Real Estate',
      link: '/industries/real-estate',
    },
    {
      label: 'Social Media',
      link: '/industries/social-media',
    },
    {
      label: 'Travel',
      link: '/industries/travel',
    },
    {
      label: 'Virtual Reality',
      link: '/industries/virtual-reality',
    },
  ],
  technology: [
    {
      label: 'Angular',
      link: '/technologies/angular',
    },
    {
      label: 'React',
      link: '/technologies/react',
    },
    {
      label: 'Swift/Objective C',
      link: '/technologies/swift-objectiveC',
    },
    {
      label: 'AWS',
      link: '/technologies/aws',
    },
    {
      label: 'MongoDB',
      link: '/technologies/mongodb',
    },
    {
      label: 'React Native',
      link: '/technologies/react-native',
    },
    {
      label: 'Docker',
      link: '/technologies/docker',
    },
    {
      label: 'Elasticsearch',
      link: '/technologies/elastic-search',
    },
    {
      label: 'Google Cloud',
      link: '/technologies/google-cloud',
    },
    {
      label: 'Java/Kotlin',
      link: '/technologies/java',
    },
    {
      label: 'Django',
      link: '/technologies/django',
    },
    {
      label: 'ExpressJS',
      link: '/technologies/express-js',
    },
    {
      label: 'GatsbyJS',
      link: '/technologies/gatsby-js',
    },
    {
      label: 'Laravel',
      link: '/technologies/laravel',
    },
    {
      label: 'NodeJS',
      link: '/technologies/node',
    },
    {
      label: 'PostgreSQL',
      link: '/technologies/postgre',
    },
    {
      label: 'Shopify',
      link: '/technologies/shopify',
    },
  ],
};

export default footerPage;

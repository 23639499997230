import React from 'react';

import {
  Header,
  Image,
  TextWraper,
  Text,
  TrapezoidShape,
  IconWrapper,
  Icon,
} from './style';

const PageHeader = ({ image, title, icon, iconType }) => (
  <Header className={iconType ? 'freewidth' : null}>
    <Image src={image} />
    {title ? (
      <TextWraper>
        <Text>{title}</Text>
      </TextWraper>
    ) : null}
    <TrapezoidShape />
    {icon ? (
      <IconWrapper>
        <Icon src={icon} />
      </IconWrapper>
    ) : null}
  </Header>
);

export default PageHeader;

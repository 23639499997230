import React from 'react';
import styled from '@emotion/styled';
import { Container } from 'layouts';
import blogList from '@constants/blogs';

const Heading = styled.h2`
  text-align: center;
`;

const AllBlogWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const BlogWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 280px;
  padding: 20px;
  margin: 20px;
  background: ${props => props.theme.colors.primary.lighter};
  border: 2px solid ${props => props.theme.colors.primary.base};
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    background: ${props => props.theme.colors.primary.base};
  }
  &:last-child: {
    margin-right: 0;
  }
`;

const BlogTitle = styled.h4`
  font-size: 24px;
  margin-bottom: 8px;
`;

const BlogDateTime = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const BlogSummary = styled.p`
  font-size: 14px;
  margin-bottom: 25px;
`;

const ReadMoreButton = styled.a`
  display: inline-block;
  font-size: 13px;
  color: ${props => props.theme.colors.black.base};
  border: 1px solid ${props => props.theme.colors.black.light};
  height: 32px;
  line-height: 30px;
  border-radius: 16px;
  width: 110px;
  text-align: center;
  transition: all 0.4s;

  &:hover {
    color: ${props => props.theme.colors.white.base};
    background: ${props => props.theme.colors.black.light};
  }
`;
const BlogsSection = () => {
  return (
    <Container>
      <Heading>Trending on our blog</Heading>
      <AllBlogWrapper>
        {blogList.map((blogItem, i) => {
          return (
            <BlogWrapper key={i}>
              <BlogTitle>{blogItem.title}</BlogTitle>
              <BlogDateTime>
                {blogItem.publishDate} {blogItem.author}
              </BlogDateTime>
              <BlogSummary>{blogItem.description}</BlogSummary>
              <ReadMoreButton href="#">Read more</ReadMoreButton>
            </BlogWrapper>
          );
        })}
      </AllBlogWrapper>
    </Container>
  );
};

export default BlogsSection;

import React from 'react';
import {
  Wrapper,
  LinkWrapper,
  ColumnWrapper,
  Title,
  FooterItem,
  CopyRightWrapper,
  CopyRightText,
  ContainerWrapper,
} from './style';
import FooterList from '@constants/footer';

const Footer = () => {
  const date = new Date();
  return (
    <Wrapper>
      <LinkWrapper>
        <ContainerWrapper>
          {Object.keys(FooterList).map((item) => {
            return (
              <ColumnWrapper key={item}>
                <Title>{item}</Title>
                {FooterList[item].map((footerItem, i) => (
                  <FooterItem key={i} href={footerItem.link}>
                    {footerItem.label}
                  </FooterItem>
                ))}
              </ColumnWrapper>
            );
          })}
        </ContainerWrapper>
      </LinkWrapper>
      <CopyRightWrapper>
        <CopyRightText>
          &copy; 2013-{date.getFullYear()} CodeMyMobile. All Rights Reserved
        </CopyRightText>
      </CopyRightWrapper>
    </Wrapper>
  );
};

export default Footer;

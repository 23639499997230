const addresses = [
  {
    address: ['CodeMyMobile', '20 Smith Dr.', 'Allentown, NJ 08501', 'USA'],
    phone: '+91-7840868988',
    email: 'info@codemymobile.com',
  },
  {
    address: ['CodeMyMobile', '122, Arcadia Greens', 'Vaishali Estate', 'Jaipur, Rajasthan 302021, India'],
    phone: '+91-7840868988',
    email: 'info@codemymobile.com',
  },
];

export default addresses;

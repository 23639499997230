import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import {
  TestimonialRow,
  SliderContainer,
  Testimonial,
  TestimonialImageWrapper,
  TestimonialDefaultImage,
  TestimonialContentContainer,
  TestimonialTitle,
  TestimonialSubtitle,
  TestimonialFeedback,
  QuoteStartIcon,
  QuoteEndIcon,
  Wrapper,
  SmileyIcon,
  Slider,
  TestimonialImage,
} from './style';
import { SectionHeading, SectionMeta } from '../Common';
import SmileyImage from '@assets/icons/smile-icon.png';
import QuoteStartImage from '@assets/icons/quote-start.png';
import QuoteEndImage from '@assets/icons/quote-end.png';
import testimonials from '@constants/testimonials';

const sliderOptions = {
  items: 1,
  autoplay: true,
  nav: false,
  loop: true,
};

const sliderEvents = {
  onDragged: function() {},
  onChanged: function() {},
};

const TestimonialSection = () => {
  return (
    <TestimonialRow>
      <Wrapper>
        <SectionHeading>
          {' '}
        </SectionHeading>
        <SectionMeta>
          not bragging at all, <SmileyIcon src={SmileyImage} />
          see for yourself...
        </SectionMeta>
        <SliderContainer>
          <Slider>
            <OwlCarousel options={sliderOptions} events={sliderEvents}>
              {testimonials.map((testimonial, idx) => (
                <Testimonial key={idx}>
                  <TestimonialImageWrapper>
                    {testimonial.image ? (
                      <TestimonialImage src={testimonial.image} />
                    ) : (
                      <TestimonialDefaultImage />
                    )}
                  </TestimonialImageWrapper>
                  <TestimonialContentContainer>
                    <TestimonialTitle>{testimonial.title}</TestimonialTitle>
                    <TestimonialSubtitle>
                      {testimonial.subtitle}
                    </TestimonialSubtitle>
                    <TestimonialFeedback>
                      <QuoteStartIcon src={QuoteStartImage} />
                      {testimonial.feedback}
                      <QuoteEndIcon src={QuoteEndImage} />
                    </TestimonialFeedback>
                  </TestimonialContentContainer>
                </Testimonial>
              ))}
            </OwlCarousel>
          </Slider>
        </SliderContainer>
      </Wrapper>
    </TestimonialRow>
  );
};

export default TestimonialSection;

import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Hexagon = styled.div`
  overflow: hidden;
  visibility: hidden;
  transform: rotate(120deg);
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size / 2}px`};
  margin: ${props => (!props.margin ? props.margin : 0)};

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin: 30px 0;
  }
`;

const HexagonIn1 = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: rotate(-60deg);
`;

const HexagonIn2 = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: ${props => props.theme.colors.primary.base};
  background-image: url(${props => props.src});
  background-size: cover;
  visibility: visible;
  transform: rotate(-60deg);
`;

const HexagonImage = ({ size, src, margin, children }) => (
  <Hexagon size={size} margin={margin}>
    <HexagonIn1>
      <HexagonIn2 src={src}></HexagonIn2>
      {children}
    </HexagonIn1>
  </Hexagon>
);

export default HexagonImage;

HexagonImage.propTypes = {
  size: PropTypes.number,
  src: PropTypes.string,
  margin: PropTypes.string,
};

HexagonImage.defaultProps = {
  size: 300,
  margin: '0 -60px 15px 0',
};

import styled from '@emotion/styled';
import { Container } from '@layouts';
import recognitionLogosImage from '../../../static/Recognition_logos.png';

export const RecognitionRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.colors.white.lightest};
`;

export const Wrapper = styled(Container)``;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const LogoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  width: 200px;
`;

export const YourStoryLogo = styled.div`
  background: url(${recognitionLogosImage}) no-repeat -103px -96px;
  width: 153px;
  height: 39px;
`;

export const TakingWingsLogo = styled.div`
  background: url(${recognitionLogosImage}) no-repeat -93px -170px;
  width: 174px;
  height: 23px;
`;

export const CIOReviewLogo = styled.div`
  background: url(${recognitionLogosImage}) no-repeat -129px -232px;
  width: 101px;
  height: 30px;
`;

export const ReadStartupsLogo = styled.div`
  background: url(${recognitionLogosImage}) no-repeat -100px -301px;
  width: 159px;
  height: 22px;
`;

export const SiliconIndiaLogo = styled.div`
  background: url(${recognitionLogosImage}) no-repeat -120px -37px;
  width: 119px;
  height: 26px;
`;

import {applicationURLs} from  '../routes';

const quickLinks = [
  {
    label: 'Services',
    link: applicationURLs.SERVICES,
  },
  {
    label: 'About Us',
    link: applicationURLs.ABOUT_US,
  },
  {
    label: 'Process',
    link: applicationURLs.PROCESS,
  },
  {
    label: 'Portfolio',
    link: applicationURLs.PORTFOLIO,
  },
  {
    label: 'Contact Us',
    link: applicationURLs.CONTACT_US,
  },
];

export default quickLinks;

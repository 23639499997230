import React from 'react';
import {
  RecognitionRow,
  Wrapper,
  LogoWrapper,
  LogoItem,
  YourStoryLogo,
  TakingWingsLogo,
  CIOReviewLogo,
  ReadStartupsLogo,
  SiliconIndiaLogo,
} from './style';
import styled from '@emotion/styled';
import { SectionHeading } from '../Common';

const RecognitionSection = () => {
  return (
    <RecognitionRow>
      <Wrapper>
        <RecognitionSectionHeading>Recognition</RecognitionSectionHeading>
        <LogoWrapper>
          <LogoItem>
            <YourStoryLogo />
          </LogoItem>
          <LogoItem>
            <SiliconIndiaLogo />
          </LogoItem>
          <LogoItem>
            <TakingWingsLogo />
          </LogoItem>
          <LogoItem>
            <CIOReviewLogo />
          </LogoItem>
          <LogoItem>
            <ReadStartupsLogo />
          </LogoItem>
        </LogoWrapper>
      </Wrapper>
    </RecognitionRow>
  );
};

const RecognitionSectionHeading = styled(SectionHeading)`
  display: flex;
  flex-direction: column;
  &:after {
    content: ' ';
    width: 10%;
    height: 3px;
    background: ${props => props.theme.colors.primary.base};
    margin: 5px auto;
  }
`;

export default RecognitionSection;

import React from 'react';
import styled from '@emotion/styled';
import technologyPartnersImage from '@static/TechnologyPartners_logos.png';
import clientsLogosImage from '@static/Clients_logos.png';
import recognitionLogosImage from '@static/Recognition_logos.png';
import theme from '@config/theme';

export const SectionHeading = styled.h2`
  font-size: 36px;
  margin: 30px 0 10px 0;
  text-align: center;
  @media (max-width: ${theme.breakpoints.l}) {
    font-size: 32px;
  }
  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 28px;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 24px;
  }
`;

export const SectionSubHeading = styled.h3`
  font-size: 26px;
  margin: 10px 0;
`;

export const SectionMeta = styled.p`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 18px;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 16px;
  }
`;

export const SectionDivider = styled.div`
  width: 60%;
  max-width: 550px;
  height: 2px;
  margin: 20px auto;
  background: ${props => props.theme.colors.white.grey};
`;

const sprite = (image, w, h, x, y) => {
  return styled.div`
    background: url(${image}) no-repeat ${x}px ${y}px;
    width: ${w}px;
    height: ${h}px;
  `;
};

export const Logo = {
  GoogleDevelopers: sprite(technologyPartnersImage, 190, 44, -56, -28),
  AWS: sprite(technologyPartnersImage, 199, 55, -51, -97),
  DigitalOcean: sprite(technologyPartnersImage, 201, 34, -50, -175),
  Azure: sprite(technologyPartnersImage, 135, 40, -83, -233),
  Ford: sprite(clientsLogosImage, 116, 45, -48, -41),
  Samsung: sprite(clientsLogosImage, 134, 20, -39, -109),
  Enjoyee: sprite(clientsLogosImage, 129, 29, -42, -159),
  Upkeep: sprite(clientsLogosImage, 136, 36, -38, -265),
  MercedesBenz: sprite(clientsLogosImage, 71, 71, -240, -17),
  PradaMilano: sprite(clientsLogosImage, 104, 68, -224, -98),
  GlobalSign: sprite(clientsLogosImage, 174, 39, -19, -209),
  Jasons: sprite(clientsLogosImage, 117, 45, -217, -190),
  Percko: sprite(clientsLogosImage, 47, 56, -252, -264),
  SiliconIndia: sprite(recognitionLogosImage, 119, 26, -120, -37),
  YourStory: sprite(recognitionLogosImage, 153, 39, -103, -96),
  TakingWings: sprite(recognitionLogosImage, 174, 23, -93, 170),
  CIOReview: sprite(recognitionLogosImage, 101, 30, -129, -232),
  ReadStartups: sprite(recognitionLogosImage, 159, 22, -100, -301),
};

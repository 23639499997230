import styled from '@emotion/styled';

export const Image = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 20px;
  background: url(${props => props.src});
  background-size: cover;
`;

export const TextWraper = styled.div`
  position: absolute;
  left: 50px;
  top: 65px;
  right: 50px;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.h1`
  font-size: 60px;
  color: ${props => props.theme.colors.white.base};
  margin: 0;
`;

export const TrapezoidShape = styled.div`
  width: 50%;
  height: 116px;
  position: absolute;
  background: ${props => props.theme.colors.white.base};
  bottom: 0;
  clip-path: polygon(14% 0%, 86% 0%, 100% 100%, 0% 100%);

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 288px;
    height: 100px;
    clip-path: polygon(72px 0, 216px 0, 288px 122px, 0px 122px);
  }
`;

export const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: 0;
`;

export const Icon = styled.img`
  width: 60px;
  height: 60px;
`;

export const Header = styled.header`
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.freewidth {
    ${IconWrapper} {
      width: auto;
      height: auto;
    }
    ${Icon} {
      width: 100px;
      height: auto;
    }
  }
`;

import axios from 'axios';

const CMM_API = axios.create({
  baseURL: `${process.env.NODE_ENV === 'production' ? 'https://api.codemymobile.com' : 'http://localhost:8080'}`,
});

CMM_API.interceptors.request.use((config) => {
  return config;
});

export default CMM_API;

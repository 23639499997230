const navBarContent = {
  services: {
    heading: 'Services',
    overview:
      'From strategy  to  development and scale, we design highly engaging experiences.',
    text: 'view all services',
    link: '/services',
  },
  industries: {
    heading: 'Industries',
    overview:
      'From strategy  to  development and scale, we design highly engaging experiences.',
    text: 'view all industries',
    link: '/industries',
  },
  technologies: {
    heading: 'Technology',
    overview:
      'From strategy  to  development and scale, we design highly engaging experiences.',
    text: 'view all technologies',
    link: '/technologies',
  },
};

export default navBarContent;

import CMM_API from './index';

const hireUs = async ({ name, email, description, files }) => {
  return CMM_API.post(
    `/website/hireus`,
    { name, email, description, files },
    {
      headers: {},
    }
  );
};

const CMM_CONTACT_API = {
  hireUs,
};

export default CMM_CONTACT_API;

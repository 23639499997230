import React from 'react';
import styled from '@emotion/styled';
import { Container } from '@layouts';
import theme from '@config/theme';
import { SectionHeading, Logo, SectionSubHeading } from './Common';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: ${props =>
    props.noBackground ? null : props.theme.colors.white.lightest};
`;

const TechWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 20px 0;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
`;

const LogoItem = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  flex: 0.2;
  @media (max-width: ${theme.breakpoints.m}) {
    flex: 0 0 50%;
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    flex: 0 0 100%;
  }
`;

const TechnologyPartners = props => {
  const { GoogleDevelopers, AWS, DigitalOcean, Azure } = Logo;

  return (
    <Wrapper>
      <TechWrapper>
        {props.titleAsSubHeading ? (
          <SectionSubHeading>Our Technology Partners</SectionSubHeading>
        ) : (
          <TechnologySectionHeading>
            Our Technology Partners
          </TechnologySectionHeading>
        )}
        <LogoWrapper>
          <LogoItem>
            <GoogleDevelopers />
          </LogoItem>
          <LogoItem>
            <AWS />
          </LogoItem>
          <LogoItem>
            <DigitalOcean />
          </LogoItem>
          <LogoItem>
            <Azure />
          </LogoItem>
        </LogoWrapper>
      </TechWrapper>
    </Wrapper>
  );
};

const TechnologySectionHeading = styled(SectionHeading)`
  display: flex;
  flex-direction: column;
  &:after {
    content: ' ';
    width: 50%;
    height: 2px;
    background: ${props => props.theme.colors.primary.base};
    margin: 5px auto;
  }
`;

export default TechnologyPartners;

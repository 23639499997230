import React from 'react';
import { Container } from 'layouts';
import QuickLinkList from '@constants/quickLinks';
import styled from '@emotion/styled';

const Heading = styled.h2``;

const InnerContainer = styled.div`
  text-align: center;
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
`;

const LinkItem = styled.a`
  font-size: 14px;
  font-weight: 600;
  margin: 10px;
  color: ${(props) => props.theme.colors.black.light};

  &:hover {
    color: ${(props) => props.theme.colors.primary.base};
  }
`;

const QuickLinks = () => {
  return (
    <Container>
      <InnerContainer>
        <Heading>Quick Links</Heading>
        <LinkWrapper>
          {QuickLinkList.map((item, i) => {
            return (
              <LinkItem key={i} href={item.link}>
                {item.label}
              </LinkItem>
            );
          })}
        </LinkWrapper>
      </InnerContainer>
    </Container>
  );
};

export default QuickLinks;

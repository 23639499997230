import React from 'react';
import Anime from 'react-anime';
import {
  StyledLink,
  NavCTA,
  NavBarLink,
  Nav,
  MenuIcon,
  LogoLight,
  LogoDark,
  DropdownLink,
  SubMenuWrapper,
  SubMenuListWrapper,
  SubMenuBox,
  SubMenuInfoBox,
  SubMenuLinkBox,
  SubMenuTitle,
  SubMenuDescription,
  SubMenuPageLink,
  BackArrow,
} from '@styles/navbarStyle';
import { subServiceRoutes, subIndustries, subTechnology, applicationURLs } from '../routes.js';
import navBarContent from '@constants/navBar.js';
import { useState } from 'react';
import {
  DesktopNavBar,
  HomeIcon,
  IndustryIcon,
  MobileCloseButton,
  MobileCTAButton,
  MobileMenuButton,
  MobileMenuChev,
  MobileMenuIcon,
  MobileMenuText,
  MobileNavLogo,
  MobileNavSideBar,
  NavBarWrapper,
  ProcessIcon,
  ServiceIcon,
  TechnologyIcon,
} from '../styles/navbarStyle.js';

const NavBarV2 = ({ themeType }) => {
  const state = {
    pinned: false,
    menuToggle: false,
    serviceDropdown: false,
    industryDropdown: false,
    technologyDropdown: false,
    serviceMenu: false,
    industryMenu: false,
    technologyMenu: false,
  };

  const [menuState, setMenuState] = useState(state);
  const [dropDownMenu, setDropDownMenu] = useState(state);

  const toggleDropdown = (type) => {
    console.log('Toggling . . .');
    setMenuState({
      ...menuState,
      [type]: !menuState[type],
    });
  };

  const closeDropdown = (type) => {
    setMenuState({
      ...menuState,
      [type]: false,
    });
  };

  const openMenu = (event, type) => {
    if (!menuState[type]) {
      setMenuState({
        ...menuState,
        serviceMenu: false,
        industryMenu: false,
        technologyMenu: false,
        [type]: true,
      });
    }
  };

  const closeMenu = (type) => {
    //const dropDownEl = dropdownMenu;
    return function closeMenuCurried(event) {
      if (dropDownMenu && !dropDownMenu.contains(event.target)) {
        closeDropdown(type);
        document.removeEventListener('click', closeMenuCurried);
      }
    };
  };

  const handleMenuToggle = () => {
    setMenuState({
      menuToggle: !menuState.menuToggle,
    });
  };

  const { menuToggle, pinned } = menuState;
  let aimeProps = {
    opacity: [0, 1],
    maxHeight: [0, 500],
    delay: (el, i) => i * 10,
  };

  let dropDownAimeProps = {
    maxHeight: [0, 500],
  };

  const mobileMenuData = [
    {
      key: 'home',
      name: 'Home',
      url: applicationURLs.HOME,
      submenu: [],
      icon: <HomeIcon />,
    },
    {
      key: 'serviceDropdown',
      name: 'Services',
      url: applicationURLs.SERVICES,
      submenu: subServiceRoutes.map((submenu) => ({
        name: submenu.name,
        url: '/services/' + submenu.route,
      })),
      icon: <ServiceIcon />,
    },
    {
      key: 'industryDropdown',
      name: 'Industries',
      url: applicationURLs.INDUSTRIES,
      submenu: subIndustries.map((submenu) => ({
        name: submenu.name,
        url: '/industries/' + submenu.route,
      })),
      icon: <IndustryIcon />,
    },
    {
      key: 'technologyDropdown',
      name: 'Technology',
      url: applicationURLs.TECHNOLOGIES,
      submenu: subTechnology.map((submenu) => ({
        name: submenu.name,
        url: '/technologies/' + submenu.route,
      })),
      icon: <TechnologyIcon />,
    },
    {
      name: 'Process',
      url: applicationURLs.PROCESS,
      submenu: [],
      icon: <ProcessIcon />,
    },
  ];

  return (
    <NavBarWrapper>
      {!menuToggle && (
        <DesktopNavBar>
          <StyledLink to="/">{themeType === 'dark' && !pinned ? <LogoDark /> : <LogoLight />}</StyledLink>

          <span role="button" tabIndex={0} onClick={handleMenuToggle} onKeyDown={handleMenuToggle}>
            <MenuIcon />
          </span>

          {/* Desktop NavBar */}
          <Nav>
            <NavBarLink to="/" activeClassName="active" item="Home">
              Home
            </NavBarLink>

            <SubMenuWrapper onMouseEnter={(event) => openMenu(event, 'serviceMenu')} onMouseLeave={closeMenu('serviceMenu')}>
              <NavBarLink to="/services" activeClassName="active" item="Services">
                Services
              </NavBarLink>
              <Anime {...dropDownAimeProps}>
                <SubMenuListWrapper show={menuState.serviceMenu} ref={(element) => setDropDownMenu(element)}>
                  <SubMenuBox>
                    <SubMenuInfoBox>
                      <SubMenuTitle> {navBarContent.services.heading} </SubMenuTitle>
                      <SubMenuDescription> {navBarContent.services.overview} </SubMenuDescription>
                      <SubMenuPageLink to={navBarContent.services.link}> {navBarContent.services.text} </SubMenuPageLink>
                    </SubMenuInfoBox>
                    <SubMenuLinkBox>
                      {subServiceRoutes.map((item, idx) => {
                        return (
                          <DropdownLink key={idx} to={'/services/' + item.route} activeClassName="active">
                            {item.name}
                          </DropdownLink>
                        );
                      })}
                    </SubMenuLinkBox>
                  </SubMenuBox>
                </SubMenuListWrapper>
              </Anime>
            </SubMenuWrapper>

            <SubMenuWrapper onMouseEnter={(event) => openMenu(event, 'industryMenu')} onMouseLeave={closeMenu('industryMenu')}>
              <NavBarLink to="/industries" activeClassName="active" item="Industries">
                Industries
              </NavBarLink>
              <Anime {...dropDownAimeProps}>
                <SubMenuListWrapper show={menuState.industryMenu} ref={(element) => setDropDownMenu(element)}>
                  <SubMenuBox>
                    <SubMenuInfoBox>
                      <SubMenuTitle>{navBarContent.industries.heading}</SubMenuTitle>
                      <SubMenuDescription>{navBarContent.industries.overview}</SubMenuDescription>
                      <SubMenuPageLink to={navBarContent.industries.link}>{navBarContent.industries.text}</SubMenuPageLink>
                    </SubMenuInfoBox>
                    <SubMenuLinkBox>
                      {subIndustries.map((item, idx) => {
                        return (
                          <DropdownLink key={idx} to={'/industries/' + item.route} activeClassName="active">
                            {item.name}
                          </DropdownLink>
                        );
                      })}
                    </SubMenuLinkBox>
                  </SubMenuBox>
                </SubMenuListWrapper>
              </Anime>
            </SubMenuWrapper>
            <SubMenuWrapper onMouseEnter={(event) => openMenu(event, 'technologyMenu')} onMouseLeave={closeMenu('technologyMenu')}>
              <NavBarLink to="/technologies" activeClassName="active" item="Technology">
                Technology
              </NavBarLink>
              <Anime {...aimeProps}>
                <SubMenuListWrapper show={menuState.technologyMenu}>
                  <SubMenuBox>
                    <SubMenuInfoBox>
                      <SubMenuTitle>{navBarContent.technologies.heading}</SubMenuTitle>
                      <SubMenuDescription>{navBarContent.technologies.overview}</SubMenuDescription>
                      <SubMenuPageLink to={navBarContent.technologies.link}>{navBarContent.technologies.text}</SubMenuPageLink>
                    </SubMenuInfoBox>
                    <SubMenuLinkBox>
                      {subTechnology.map((item, idx) => {
                        return (
                          <DropdownLink key={idx} to={'/technologies/' + item.route} activeClassName="active">
                            {item.name}
                          </DropdownLink>
                        );
                      })}
                    </SubMenuLinkBox>
                  </SubMenuBox>
                </SubMenuListWrapper>
              </Anime>
            </SubMenuWrapper>

            <NavBarLink to="/process" activeClassName="active" item="Process">
              Process
            </NavBarLink>

            <NavCTA to={applicationURLs.CONTACT_US}>Hire Us</NavCTA>
          </Nav>
        </DesktopNavBar>
      )}

      {/* Mobile Side NavBar */}
      <MobileNavSideBar className={menuToggle ? 'open' : ''}>
        <MobileNavLogo>
          <StyledLink to="/">{themeType === 'dark' && !pinned ? <LogoDark /> : <LogoLight />}</StyledLink>
        </MobileNavLogo>
        {/* <span role="button" tabIndex={0} onClick={handleMenuToggle} onKeyDown={handleMenuToggle}><CloseIcon /></span> */}
        <MobileCloseButton tabIndex={0} onClick={handleMenuToggle} onKeyDown={handleMenuToggle}>
          x
        </MobileCloseButton>

        {!menuState.industryDropdown &&
          !menuState.technologyDropdown &&
          !menuState.serviceDropdown &&
          mobileMenuData.map((menu) => (
            <MobileMenuButton>
              <MobileMenuText to={menu.url}>
                <MobileMenuIcon>
                  {' '}
                  {menu.icon} <span>{menu.name}</span>
                </MobileMenuIcon>
                {menu.submenu.length > 0 ? <MobileMenuChev onClick={() => toggleDropdown(menu.key)} /> : ''}
              </MobileMenuText>
            </MobileMenuButton>
          ))}

        {mobileMenuData
          .filter((menu) => menuState[menu.key] === true)
          .map((menu) => (
            <>
              {['serviceDropdown', 'industryDropdown', 'technologyDropdown'].includes(menu.key) && (
                <>
                  <MobileMenuButton>
                    <MobileMenuText>
                      <MobileMenuIcon>
                        {' '}
                        <BackArrow onClick={() => toggleDropdown(menu.key)} /> {menu.icon} <span>{menu.name}</span>
                      </MobileMenuIcon>
                    </MobileMenuText>
                  </MobileMenuButton>

                  {menu.submenu.map((submenu) => (
                    <MobileMenuButton to={submenu.url}>
                      <MobileMenuText>{submenu.name}</MobileMenuText>
                    </MobileMenuButton>
                  ))}
                </>
              )}
            </>
          ))}

        <MobileCTAButton to={applicationURLs.CONTACT_US}>Hire Us</MobileCTAButton>
      </MobileNavSideBar>
    </NavBarWrapper>
  );
};

export default NavBarV2;

import RyanChanPic from '@assets/testimonials/ryan.jpeg';
import ChiranjeevPic from '@assets/testimonials/chiranjeev.jpeg';
import NicholePic from '@assets/testimonials/nichole.jpg';
import KanavPic from '@assets/testimonials/kanav.jpg';

const testimonials = [
  {
    title: 'Ryan Chan',
    subtitle: 'CEO and Founder, Upkeep',
    image: RyanChanPic,
    feedback:
      'CodeMyMobile as a team helped us design and engineer UpKeep. We have come a long way from building an MVP to scaling the product towards being the No.1 CMMS in the world for maintenance and facilities. They are super reliable and we totally count on their extraordinary skills.',
  },
  {
    title: 'Chiranjeev Bordoloi',
    subtitle: 'CEO and Founder, Qwikaccess',
    image: ChiranjeevPic,
    feedback:
      'Their communication levels and genuine empathy is outstanding. CodeMyMobile has a genuine love for innovative ideas, apps. Their work resonates with world class standards!',
  },
  {
    title: 'Nicole',
    subtitle: 'CEO/Co-Founder',
    image: NicholePic,
    feedback:
      "We work as a startup, so we're very fast-paced, and CodeMyMobile has adapted to our pace. We're very happy with their team. A collaboration of this kind is a two-way conversation.",
  },
  {
    title: 'Kanav Garg',
    subtitle: 'CEO, Erfolg Technologies',
    image: KanavPic,
    feedback:
      'CodeMyMobile were one of the top vendors that we worked with. We were impressed with their enthusiasm, passion and how they brought constructive ideas to the project that helped in making the product better than expected while achieving unmatched quality and professional standards.',
  },
  {
    title: 'Charlotte’s Best',
    subtitle: 'Co-Founder',
    feedback:
      'They just take it and run with it, and if they have any questions, they’ll chase me down and ask me. But, they just run with it...really makes me want to work with them again and again.',
  },
  {
    title: 'Lacratelle (Online Retail Company)',
    subtitle: 'Head of E-commerce',
    feedback:
      "CodeMyMobile's services are outstanding. They are probably the best vendor that I’ve had the opportunity to work with in this space. They work closely with design and product teams and go an extra mile to provide sleek user interface for our ecommerce portal.",
  },
];

export default testimonials;

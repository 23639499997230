import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { ThemeProvider } from '@emotion/react';
import { css, Global } from '@emotion/react';
import PropTypes from 'prop-types';
import 'typeface-montserrat';
import 'typeface-vidaloka';
import { SEO } from 'components';
import {
  NavBar,
  QuickLinks,
  Social,
  Blogs,
  WorkTogether,
  Footer,
} from 'layouts';
import { SectionDivider } from 'components/Common';
import theme, { paletteLight, paletteDark } from '../../config/theme';
import headroom from '../styles/headroom';
import NavBarV2 from './NavBarV2';

const Divider = styled.p`
  width: 60%;
  max-width: 480px;
  height: 2px;
  margin: 40px auto;
  background: ${(props) => props.theme.colors.white.dark};
`;

const FooterContainer = styled.div`
  background: ${(props) => props.theme.colors.white.lightest};
`;

const Layout = ({ children, themeType }) => {
  if (themeType === 'dark') {
    theme.navbar = paletteDark;
  } else {
    theme.navbar = paletteLight;
  }
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Global
          styles={css`
            *,
            *:before,
            *:after {
              box-sizing: inherit;
            }
            html {
              text-rendering: optimizeLegibility;
              overflow-x: hidden;
              box-sizing: border-box;
              -ms-overflow-style: scrollbar;
              -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
            html,
            body {
              width: 100%;
              height: 100%;
              margin: 0;
              padding: 0;
            }

            body {
              display: flex;
              flex-direction: column;
              min-height: 100vh;
            }
            a {
              color: ${theme.colors.link};
              transition: color 0.5s;
              text-decoration: none;
            }
            a:hover {
              text-decoration: none;
              color: ${theme.colors.linkHover};
            }
            h1,
            h2,
            h3,
            h4 {
              font-family: ${theme.fontFamily.heading};
            }

            ${headroom}
          `}
        />
        <SEO />

        {/* <NavBar themeType={themeType} /> */}
        <NavBarV2 />
        {children}
        <FooterContainer>
          <WorkTogether />
          <SectionDivider />
          <QuickLinks />
          <Social />
          <Footer />
        </FooterContainer>
      </Fragment>
    </ThemeProvider>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};

import React from 'react';
import styled from '@emotion/styled';
import { Container } from '@layouts';
import theme from '@config/theme';
import { SectionHeading, Logo } from './Common';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props =>
    props.noBackground ? null : props.theme.colors.white.lightest};
  padding: 40px 0 20px 0;
`;

const LogoWrapper = styled(Container)`
  display: flex;
  flex-flow: row wrap;
  padding: 20px 0;
  align-items: center;
  justify-content: space-evenly;
`;

const LogoItem = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  min-width: 200px;
  @media (max-width: ${theme.breakpoints.m}) {
    flex: 0 0 50%;
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    min-width: auto;
    flex: 0 0 100%;
  }
`;

const BrandsWorkedWithUs = props => {
  const {
    Ford,
    MercedesBenz,
    Samsung,
    Upkeep,
    PradaMilano,
    GlobalSign,
    Percko,
    Jasons,
    Enjoyee,
  } = Logo;

  return (
    <Wrapper noBackground={props.noBackground}>
      <BrandSectionHeading>Brands who have worked with us</BrandSectionHeading>
      <LogoWrapper>
        <LogoItem>
          <Ford />
        </LogoItem>
        <LogoItem>
          <MercedesBenz />
        </LogoItem>
        <LogoItem>
          <Samsung />
        </LogoItem>
        <LogoItem>
          <Upkeep />
        </LogoItem>
        <LogoItem>
          <PradaMilano />
        </LogoItem>
        <LogoItem>
          <GlobalSign />
        </LogoItem>
        <LogoItem>
          <Percko />
        </LogoItem>
        <LogoItem>
          <Jasons />
        </LogoItem>
        <LogoItem>
          <Enjoyee />
        </LogoItem>
      </LogoWrapper>
    </Wrapper>
  );
};

const BrandSectionHeading = styled(SectionHeading)`
  display: flex;
  flex-direction: column;
  &:after {
    content: ' ';
    width: 50%;
    height: 2px;
    background: ${props => props.theme.colors.primary.base};
    margin: 5px auto;
  }
`;

export default BrandsWorkedWithUs;

import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import {
  PortfolioSliderContainer,
  PortfolioSlide,
  PortfolioContentContainer,
  PortfolioContentCol,
  AppLogoWrapper,
  AppLogo,
  AppOverviewText,
  PortfolioSlideGradient,
  PortfolioImageCol,
  PortfolioImage,
  AppHeading,
  AppLogoContainer,
  AppTitle,
} from './style';
import upkeepLogo from '@static/logo/upkeep-white.png';
import upkeepPortfolioImage from '@static/portfolio/upkeep-mockup-3.png';
import jasonsLogo from '@static/logo/jasons-logo.png';
import jasonsPortfolioImage from '@static/portfolio/jasons-i-phone-x-mockups.png';
import rollLogo from '@static/logo/roll-logo.png';
import rollPortfolioImage from '@static/portfolio/roll-app-screenshots.png';
import dyaniaLogo from '@static/logo/dyania-logo.png';
import dyaniaPortfolioImage from '@static/portfolio/dyania-ipad-synapsis.png';
import loloLogo from '@static/logo/lolo-paris.png';
import loloPortfolioImage from '@static/portfolio/lolo-paris-screen-desktop.png';
import perckoLogo from '@static/logo/percko-logo-new.png';
import perckoPortfolioImage from '@static/portfolio/percko-web-desktop.webp';
import isosLogo from '@static/logo/isosconnect.png';
import isosPortfolioImage from '@static/portfolio/isosconnect-web-desktop.png';
import mootshLogo from '@static/logo/mootsh-logo.png';
import mootshPortfolioImage from '@static/portfolio/mootsh-web-desktop.png';
import workbookLogo from '@static/logo/w-logo.png';
import workbookPortfolioImage from '@static/portfolio/workbook-web-desktop.webp';

const sliderOptions = {
  items: 1,
  nav: false,
  autoplay: true,
  rewind: true,
};

const sliderEvents = {
  onDragged: function() {},
  onChanged: function() {},
};

const PortfolioSection = () => {
  return (
    <PortfolioSliderContainer>
      <OwlCarousel options={sliderOptions} events={sliderEvents}>

        <PortfolioSlide portfolio={'upkeep'}>
          <PortfolioContentContainer>
            <PortfolioContentCol>
              <AppLogoWrapper>
                <AppLogo src={upkeepLogo} />
              </AppLogoWrapper>
              <AppHeading>
                We engineered world&apos;s #1 mobile-first SaaS for Maintenance
                &amp; Facilities.
              </AppHeading>
              <AppOverviewText>
                Upkeep is a mobile-first CMMS software that makes technicians
                more agile and maintenance teams more productive. It is
                top-rated by CAPTERRA, Software Advice and G2 crowd and highly
                recommended by maintenance professionals.
              </AppOverviewText>
              <AppOverviewText>
                It is known for its sleek user interface, mobile centered-ness
                and how it is perfect for data-driven maintenance managers, and
                we unapologetically take pride in having crafted it with such
                excellence.
              </AppOverviewText>
            </PortfolioContentCol>
            <PortfolioImageCol>
              <PortfolioImage src={upkeepPortfolioImage} />
            </PortfolioImageCol>
          </PortfolioContentContainer>
          <PortfolioSlideGradient portfolio={'upkeep'} position={'top'} />
          <PortfolioSlideGradient portfolio={'upkeep'} position={'bottom'} />
        </PortfolioSlide>

        <PortfolioSlide portfolio={'jasons'}>
          <PortfolioContentContainer>
            <PortfolioContentCol>
              <AppLogoWrapper>
                <AppLogo src={jasonsLogo} />
              </AppLogoWrapper>
              <AppHeading>
                We built Los Angeles' Fastest Liquor Delivery App that&apos;s
                HIGH{' '}
                <span role="img" aria-label="booze">
                  🍺
                </span>{' '}
                on the app store charts!
              </AppHeading>
              <AppOverviewText>
                Ordering wine and liquor in LA has never been this easy.
                Jason&apos;s Wine and Spirits provides you with unique wine and
                liquor selections to your doorstep. From all-day quick delivery
                to competitive pricing, scheduling gifts for loved ones,
                Jason&apos;s app has been driving great reviews for its fluidic
                functionality, simple and user-friendly UI.
              </AppOverviewText>
              <AppOverviewText>
                This e-Commerce app has A smart recommendation system and easy
                checkouts. We won&apos;t let LA fall.
              </AppOverviewText>
            </PortfolioContentCol>
            <PortfolioImageCol>
              <PortfolioImage
                src={jasonsPortfolioImage}
                verticallyAligned={true}
              />
            </PortfolioImageCol>
          </PortfolioContentContainer>
          <PortfolioSlideGradient portfolio={'jasons'} position={'top'} />
          <PortfolioSlideGradient portfolio={'jasons'} position={'bottom'} />
        </PortfolioSlide>

        <PortfolioSlide portfolio={'roll'}>
          <PortfolioContentContainer>
            <PortfolioContentCol>
              <AppLogoContainer>
                <AppLogo src={rollLogo} />
                <AppTitle>Roll</AppTitle>
              </AppLogoContainer>
              <AppHeading>
                The holy grail for Jiu-Jitsu Social Networking app!
              </AppHeading>
              <AppOverviewText>
                Ever wanted to Roll Hard - Fight easy? Roll helps you with that.
                It&apos;s a Brazilian Jiu-Jitsu (BJJ) app which allows fighters
                and academies to manage their membership, track training stats
                and compare themselves with other fighters. It&apos;s more like
                your day-to-day dating app for lovers of this sport, but with
                less swiping, and more fighting.
              </AppOverviewText>
              <AppOverviewText>
                With simplified analytics, easy academy attendance check-ins and
                seamless stat comparisons, this app has received a lot of love
                from the Jiu-Jitsu sports enthusiasts. Happy Rolling!
              </AppOverviewText>
            </PortfolioContentCol>
            <PortfolioImageCol>
              <PortfolioImage
                src={rollPortfolioImage}
                verticallyAligned={true}
              />
            </PortfolioImageCol>
          </PortfolioContentContainer>
          <PortfolioSlideGradient portfolio={'roll'} position={'top'} />
          <PortfolioSlideGradient portfolio={'roll'} position={'bottom'} />
        </PortfolioSlide>

        <PortfolioSlide portfolio={'dyania'}>
          <PortfolioContentContainer>
            <PortfolioContentCol>
              <AppLogoContainer>
                <AppLogo src={dyaniaLogo} />
                <AppTitle>Dyania Health</AppTitle>
              </AppLogoContainer>
              <AppOverviewText>
                We are working with Dyania to bridge computational and healthcare expertise, backed by prominent health industry experts.
              </AppOverviewText>
              <AppOverviewText>
                We are building, training NLP tools that are specialized to solve complex medical problems and enrich the clinical research process for therapies curing the most life-impacting diseases.
              </AppOverviewText>
            </PortfolioContentCol>
            <PortfolioImageCol>
              <PortfolioImage
                src={dyaniaPortfolioImage}
                verticallyAligned={true}
              />
            </PortfolioImageCol>
          </PortfolioContentContainer>
          <PortfolioSlideGradient portfolio={'dyania'} position={'top'} />
          <PortfolioSlideGradient portfolio={'dyania'} position={'bottom'} />
        </PortfolioSlide>

        <PortfolioSlide portfolio={'lolo'}>
          <PortfolioContentContainer>
            <PortfolioContentCol>
              <AppLogoContainer>
                <AppLogo src={loloLogo} />
                <AppTitle>LoLo Paris</AppTitle>
              </AppLogoContainer>
              <AppOverviewText>
                Lolo paris - is a young custom-made lingerie brand, we are building custom adaptive web solutions to set them up for success in this niche but very promising market.
              </AppOverviewText>
              <AppOverviewText>
                We also helped them build their marketing automation and customer service tools and moved them over to headless ecommerce solution that helped them grow 13% MOM.
              </AppOverviewText>
            </PortfolioContentCol>
            <PortfolioImageCol>
              <PortfolioImage
                src={loloPortfolioImage}
                verticallyAligned={true}
              />
            </PortfolioImageCol>
          </PortfolioContentContainer>
          <PortfolioSlideGradient portfolio={'lolo'} position={'top'} />
          <PortfolioSlideGradient portfolio={'lolo'} position={'bottom'} />
        </PortfolioSlide>

        <PortfolioSlide portfolio={'percko'}>
          <PortfolioContentContainer>
            <PortfolioContentCol>
              <AppLogoContainer>
                <AppLogo src={perckoLogo} />
                <AppTitle>Percko</AppTitle>
              </AppLogoContainer>
              <AppOverviewText>
                We are enabling team Percko with an adaptive and scalable ecommerce solution to help them sell their innovative products for <b>"the world's leading cause of disability"</b> - back pain.
              </AppOverviewText>
              <AppOverviewText>
                We migrated over 100k orders and thousands of customer data from woocommerce to a headless ecommerce solution and helped them in scaling up to a Million dollar monthly sales, after the migration Percko saw 3 to 5x improvement in web vitals and page speed
              </AppOverviewText>
            </PortfolioContentCol>
            <PortfolioImageCol>
              <PortfolioImage
                src={perckoPortfolioImage}
                verticallyAligned={true}
              />
            </PortfolioImageCol>
          </PortfolioContentContainer>
          <PortfolioSlideGradient portfolio={'percko'} position={'top'} />
          <PortfolioSlideGradient portfolio={'percko'} position={'bottom'} />
        </PortfolioSlide>

        <PortfolioSlide portfolio={'isosconnect'}>
          <PortfolioContentContainer>
            <PortfolioContentCol>
              <AppLogoContainer>
                <AppLogo src={isosLogo} />
                <AppTitle>iSOSconnect</AppTitle>
              </AppLogoContainer>
              <AppOverviewText>
                We created a smart mobile first experience for iSOS team that helps medical professionals turn enquiries into paying clients.
              </AppOverviewText>
              <AppOverviewText>
                We worked with them from the ground up to come up with a POC and delivered a finished product that helped them gather customers quickly and enabled their business to muster confidence within the VC network to raise their 1st round.
              </AppOverviewText>
            </PortfolioContentCol>
            <PortfolioImageCol>
              <PortfolioImage
                src={isosPortfolioImage}
                verticallyAligned={true}
              />
            </PortfolioImageCol>
          </PortfolioContentContainer>
          <PortfolioSlideGradient portfolio={'isosconnect'} position={'top'} />
          <PortfolioSlideGradient portfolio={'isosconnect'} position={'bottom'} />
        </PortfolioSlide>

        <PortfolioSlide portfolio={'mootsh'}>
          <PortfolioContentContainer>
            <PortfolioContentCol>
              <AppLogoContainer>
                <AppLogo src={mootshLogo} />
                <AppTitle>Mootsh Photos</AppTitle>
              </AppLogoContainer>
              <AppOverviewText>
                We have been working with Mootsh to build and scale their innovative photo printing and collection services.
              </AppOverviewText>
              <AppOverviewText>
                We have been deeply involved to solve their product, business and operational challenges with our tech solutions to help them bring to life their dream product from 5 to 5000 customers and $60k annual sales, we are now in the process of migrating them from wordpress to help them scale to over $100k.
              </AppOverviewText>
            </PortfolioContentCol>
            <PortfolioImageCol>
              <PortfolioImage
                src={mootshPortfolioImage}
                verticallyAligned={true}
              />
            </PortfolioImageCol>
          </PortfolioContentContainer>
          <PortfolioSlideGradient portfolio={'mootsh'} position={'top'} />
          <PortfolioSlideGradient portfolio={'mootsh'} position={'bottom'} />
        </PortfolioSlide>

        <PortfolioSlide portfolio={'workbook'}>
          <PortfolioContentContainer>
            <PortfolioContentCol>
              <AppLogoContainer>
                <AppLogo src={workbookLogo} />
                <AppTitle>WorkbookMint</AppTitle>
              </AppLogoContainer>
              <AppOverviewText>
                One of the most exciting product in the Web3 space.
              </AppOverviewText>
              <AppOverviewText>
                We are helping make a 44 year old print and online source for illustrators, connect with Professional Artists, Filmmakers & Production Specialists with creative buyers worldwide by converting their original art into NFTs.
              </AppOverviewText>
            </PortfolioContentCol>
            <PortfolioImageCol>
              <PortfolioImage
                src={workbookPortfolioImage}
                verticallyAligned={true}
              />
            </PortfolioImageCol>
          </PortfolioContentContainer>
          <PortfolioSlideGradient portfolio={'workbook'} position={'top'} />
          <PortfolioSlideGradient portfolio={'workbook'} position={'bottom'} />
        </PortfolioSlide>

      </OwlCarousel>
    </PortfolioSliderContainer>
  );
};

export default PortfolioSection;

import styled from '@emotion/styled';
import theme from '@config/theme';

export const PortfolioSliderContainer = styled.div`
  position: relative;

  .owl-stage-outer {
    overflow-x: hidden;
  }
  .owl-stage {
    display: flex;
  }

  .owl-dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
  }
  .owl-dots {
    margin-top: 25px;
  }
  .owl-dots button {
    outline: none;
  }
  .owl-dot span {
    background: #869791 !important;
    height: 12px !important;
    width: 12px !important;
  }
  .owl-dot:hover span {
    background: #fff;
  }
  .owl-dot.active span {
    background: #000 !important;
  }

  .owl-nav {
    display: none;
  }
`;

export const PortfolioSlider = styled.div`
  position: relative;
`;

export const PortfolioSlide = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  background: #f8f8f8;
  box-sizing: content-box;

  border-bottom: ${props =>
    `5px solid ${getPortfolioBorderColor(props.portfolio)}`};

  @media screen and (min-width: 768px) {
    height: 750px;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    height: 1000px;
    min-height: 1000px;
    border-top: ${props =>
      `5px solid ${getPortfolioBorderColor(props.portfolio)}`};
  }

  @media screen and (max-width: 1200px) {
    padding: 0 50px;
  }
`;

export const PortfolioSlideGradient = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 140px;
  z-index: 1;
  top: ${props => (props.position === 'top' ? 0 : null)};
  bottom: ${props => (props.position === 'bottom' ? 0 : null)};
  background: ${props =>
    getPortfolioSlideGradient(props.portfolio, props.position)};

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    display: ${props => (props.position === 'top' ? 'none' : 'block')};
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: 'block';
  }
`;

export const PortfolioContentContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const PortfolioContentCol = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  z-index: 2;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    height: auto;
    padding: 80px 0 0 0;
    flex: 1;
    text-align: center;
    justify-content: flex-start;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    height: auto;
    padding: 30px 0 0 0;
    flex: 1;
    text-align: center;
    justify-content: flex-start;
  }

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    height: 750px;
  }
`;

export const AppLogoWrapper = styled.div`
  margin-bottom: 35px;

  img {
    width: 175px;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 0 auto;
    .app-logo-container {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
export const AppLogoContainer = styled.div`
  margin-bottom: 35px;
  display: flex;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 0 auto;
  }
`;
export const AppLogo = styled.img`
  margin: 0;
  width: 70px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 50px;
  }
`;
export const AppTitle = styled.div`
  font-size: 50px;
  font-family: 'Montserrat';
  margin-left: 25px;
  font-weight: bold;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 30px;
  }
`;
export const AppHeading = styled.h2`
  font-size: 30px;
  color: ${props => props.theme.colors.black.dark};
  font-family: ${props => props.theme.fontFamily.heading};

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 20px;
    margin: 20px 0;
    text-align: center;
  }
`;
export const AppOverviewText = styled.p`
  font-family: 'Montserrat';
  color: ${props => props.theme.colors.black.dark};
  margin-right: 30px;

  @media (min-width: 1025px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (max-width: 1024px) {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 25px;
  }
`;

export const PortfolioImageCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
  width: 40%;
  min-width: 500px;
  overflow: hidden;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 90%;
    max-width: 425px;
    height: auto;
    min-width: auto;
    height: 400px;
    flex: 1;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex: none;
  }
  @media (min-width: ${props => props.theme.breakpoints.m}) {
    height: 750px;
  }
`;

export const PortfolioImage = styled.img`
  margin-bottom: 0;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: ${props => (props.verticallyAligned ? '90%' : 'auto')};
    max-width: ${props => (props.verticallyAligned ? '280px' : null)};
    height: ${props => (props.verticallyAligned ? 'auto' : '100%')};
    margin: ${props => (props.verticallyAligned ? '20px auto' : '0')};
  }
  @media (min-width: ${props => props.theme.breakpoints.m}) {
    width: auto;
    max-width: ${props => (props.verticallyAligned ? '450px' : null)};
    height: ${props => (props.verticallyAligned ? 'auto' : '100%')};
  }
`;

const getPortfolioSlideGradient = (portfolioKey, gradientPosition) => {
  return `linear-gradient(to ${
    gradientPosition === 'top' ? 'bottom' : 'top'
  }, ${getPortfolioGradientColor(portfolioKey)}, transparent)`;
};

const getPortfolioGradientColor = portfolioKey => {
  switch (portfolioKey) {
    case 'upkeep':
      return 'rgba(252, 44, 65, 0.33)';
    case 'roll':
      return 'rgba(62, 85, 242, 0.33)';
    case 'jasons':
      return 'rgba(83, 83, 83, 0.33)';
    case 'trecto':
      return 'rgba(149, 43, 153, 0.33)';
    case 'competish':
      return 'rgba(51, 56, 77, 0.33)';
    case 'micmaps':
      return 'rgba(15, 236, 187, 0.33)';
    case 'dyania':
      return 'rgba(73, 248, 194, 0.33)';
    case 'lolo':
      return 'rgba(203, 139, 128, 0.33)';
    case 'percko':
      return 'rgba(33, 33, 38, 0.33)';
    case 'isosconnect':
      return 'rgba(30, 172, 232, 0.33)';
    case 'mootsh':
      return 'rgba(138, 142, 66, 0.33)';
    case 'workbook':
      return 'rgba(4, 17, 29, 0.33)';
    default:
      return theme.colors.primary.base;
  }
};

const getPortfolioBorderColor = portfolioKey => {
  switch (portfolioKey) {
    case 'upkeep':
      return 'rgba(252, 44, 65, 1)';
    case 'roll':
      return 'rgba(62, 85, 242, 1)';
    case 'jasons':
      return 'rgba(83, 83, 83, 1)';
    case 'trecto':
      return 'rgba(149, 43, 153, 1)';
    case 'competish':
      return 'rgba(51, 56, 77, 1)';
    case 'micmaps':
      return 'rgba(15, 236, 187, 1)';
    case 'dyania':
      return 'rgba(73, 248, 194, 1)';
    case 'lolo':
      return 'rgba(203, 139, 128, 1)';
    case 'percko':
      return 'rgba(33, 33, 38, 1)';
    case 'isosconnect':
      return 'rgba(30, 172, 232, 1)';
    case 'mootsh':
      return 'rgba(138, 142, 66, 1)';
    case 'workbook':
      return 'rgba(4, 17, 29, 1)';
    default:
      return theme.colors.primary.base;
  }
};

import React from 'react';
import { Container } from 'layouts';
import styled from '@emotion/styled';
import theme from '@config/theme';
import { SectionDivider } from 'components/Common';
import TwitterIcon from '@assets/icons/Twitter_Icon.svg';
import FacebookIcon from '@assets/icons/Facebook_Icon-01.svg';
import InstagramIcon from '@assets/icons/Instagram_Icon.svg';
import LinkedinIcon from '@assets/icons/Linkedin_Icon.svg';
import PhoneIcon from '@assets/icons/Mobile_Icon.svg';

import addressList from '@constants/address';

const Heading = styled.h2``;

const InnerContainer = styled.div`
  text-align: center;
`;

const SocialIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  padding: 15px 0;
`;

const SocialIcon = styled.a`
  display: flex;
  margin: 0 10px;
`;

const TwitterIconStyled = styled(TwitterIcon)`
  width: 40px;
  height: 40px;
  .circle {
    fill: ${theme.colors.black.light};
  }
  &:hover {
    .circle {
      fill: ${theme.colors.primary.base};
    }
  }
  transition: background-color 250ms ease;
`;
const FacebookIconStyled = styled(FacebookIcon)`
  width: 40px;
  height: 40px;
  .circle {
    fill: ${theme.colors.black.light};
  }
  &:hover {
    .circle {
      fill: ${theme.colors.primary.base};
    }
  }
  transition: background-color 250ms ease;
`;
const InstagramIconStyled = styled(InstagramIcon)`
  width: 40px;
  height: 40px;
  .circle {
    fill: ${theme.colors.black.light};
  }
  &:hover {
    .circle {
      fill: ${theme.colors.primary.base};
    }
  }
  transition: background-color 250ms ease;
`;
const LinkedinIconStyled = styled(LinkedinIcon)`
  width: 40px;
  height: 40px;
  .circle {
    fill: ${theme.colors.black.light};
  }
  &:hover {
    .circle {
      fill: ${theme.colors.primary.base};
    }
  }
  transition: background-color 250ms ease;
`;

const AddressesContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 20px 0;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    margin: 40px 0 20px 20px;
  }
`;

const PhoneIconStyled = styled(PhoneIcon)`
  width: 30px;
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    position: absolute;
    left: -25px;
    top: 20px;
  }
`;

const AddressWrapper = styled.div`
  display: flex;
  position: relative;
  font-size: 13px;
  font-weight: 500;
  flex-direction: row;
  align-items: flex-start;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    flex-direction: column;
  }
`;

const AddressColumn = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin: 0 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    margin: 20px 20px 40px 20px;
  }
`;

const PhoneNumber = styled.div`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 30px;
  cursor: pointer;
  text-align: left;

  &:hover {
    color: ${theme.colors.primary.base};
  }
`;

const Line = styled.div`
  line-height: 1.6;
  text-align: left;
  padding-left: 5px;
`;

const Space = styled.div`
  line-height: 2;
`;

const SocialLinks = () => {
  return (
    <Container>
      <InnerContainer>
        <Heading>Let’s Get Social</Heading>
        <SocialIconWrapper>
          <SocialIcon href="https://twitter.com/codemymobile" target="_blank">
            <TwitterIconStyled />
          </SocialIcon>
          <SocialIcon
            href="https://www.facebook.com/Codemymobile/"
            target="_blank"
          >
            <FacebookIconStyled />
          </SocialIcon>
          <SocialIcon
            href="https://www.instagram.com/codemymobile"
            target="_blank"
          >
            <InstagramIconStyled />
          </SocialIcon>
          <SocialIcon
            href="https://www.linkedin.com/company/codemymobile"
            target="_blank"
          >
            <LinkedinIconStyled />
          </SocialIcon>
        </SocialIconWrapper>
        <SectionDivider />
        <AddressesContainer>
          <AddressWrapper>
            {addressList.map((item, i) => {
              return (
                <AddressColumn key={i}>
                  <React.Fragment>
                    {item.address.map((addLine, j) => {
                      return <Line key={j}>{addLine}</Line>;
                    })}
                    <Space>&nbsp;</Space>
                    <Line>Phone: {item.phone}</Line>
                    <Line>Email:{item.email}</Line>
                  </React.Fragment>
                </AddressColumn>
              );
            })}
          </AddressWrapper>
        </AddressesContainer>
      </InnerContainer>
    </Container>
  );
};

export default SocialLinks;
